import $ from 'jquery';
import jQuery from 'jquery';
import CONFIG from '../config';
import React  from 'react';
import "../index.css";
import * as Utils from '../lib/utils';

require('bootstrap-toggle');

window.jQuery = jQuery;
require("jsgrid");

const API_HOST = CONFIG.API_HOST;

class ReferenceForm extends React.Component{
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        const params = Utils.getParams();

        this.state = {
            candidate : null,
            reference : null,
            otherRelationClass : "hidden",
            tokenFound : params.token ? true : false,
            token : params.token
        };
    }

    componentDidMount() {

        const params = Utils.getParams();

        $.ajax({
            url: API_HOST + "/reference_data?token=" + params.token,
            dataType: "json",
            data: null
        }).then((referencesResult)=>{

            // console.log("referencesResult", referencesResult);

            if(referencesResult && referencesResult.status){

                if(referencesResult.status === "UNAVAILABLE"){
                    this.setState({
                        referenceMessage : "Reference has already been completed."
                    });
                }

            }else{

                $.ajax({
                    url: API_HOST + "/candidate_data/" + referencesResult[0].candidate_id,
                    dataType: "json",
                    data: null
                }).then((candidateResult)=>{
    
                    this.setState({
                        candidate : candidateResult,
                        reference : referencesResult[0]
                    })
    
                });
    
            }

        });
        
    }

    getContactTypeQuestions(type) {
        // console.log("type", type);

        const contactTypeQuestions = {
            academic : [
                "How long and in what capacity have you known the candidate?",
                "How would you describe the individual's overall performance on a scale from 1-10 (10 being the highest)?",
                "What would you consider some of the individual's strengths?",
                "In what areas did the individual appear to need additional assistance to develop?",
                "How timely was the individual in completing assignments and meeting deadlines?",
                "Would you describe the individual as someone who works best as part of a team or independently?",
                "Describe the individual's working relationships with students, the public, or specific groups/individuals served.",
                "Please comment on the candidate's attendance and punctuality.",
                "Did the individual communicate effectively, verbally and in writing?",
                "How well did the candidate manage crisis, pressure, or stressful situations?",
                "What type of advice would you give his or her next employer to ensure success?",
                "Given the position as I've described it, would you hire for the position?",
                "Is there anything I haven't asked that you'd like hiring team to be aware of?"
            ],
            supervisor : [
                "How long and in what capacity have you known the candidate?",
                "What were the individual's title and responsibilities at the time the two of you worked together?",
                "How did he/she perform in their role?",
                "What was it like to manage him/her? How much direction/oversight did he/she need?",
                "How does he/she handle new challenges of any sort?",
                "How does he/she respond to criticism?",
                "What are his/her principal strengths?",
                "What are his/her principal weaknesses?",
                "Did the individual communicate effectively, verbally and in writing?",
                "Was she/he more of an individual contributor or team-orientated? Why do you think this?",
                "What were the individual's greatest contributions to the organization or major accomplishments?",
                "What type of advice would you give his or her next employer to ensure success?",
                "Reason for leaving: Voluntary or involuntary? Explain.",
                "Is there any reason why your company would not rehire this individual? If yes, explain.",
                "Is there anything I haven't asked that you'd like hiring team to be aware of?"
            ],
            client : [
                "How long and in what capacity have you known the candidate?",
                "Please describe the type of work and the timeframe in which the candidate performed work for you.",
                "How would you describe the candidate's ethics and value system?",
                "Please describe the candidate's quality of work and the relationship you built with him/her.",
                "Tell me about the candidate's ability to effectively manage stressful or crisis situations.",
                "Did the individual communicate and present himself/herself professionally, verbally and in writing?",
                "How would you describe the candidate's availability/dependability relative to you and your needs?",
                "Did the candidate successfully drive projects to completion on-time and on budget? If so, please provide an example.",
                "What would you categorize as the candidate's strengths?",
                "What would you categorize as the candidate's weaknesses?",
                "(If applicable) What was the reason this client relationship ended?",
                "Would you work with this candidate again?",
                "Is there anything I haven't asked that you'd like hiring team to be aware of?"
            ],
            peer : [
                "How long and in what capacity have you known the candidate?",
                "What was the candidate's title during this time?",
                "Please describe the candidate's job duties.",
                "How well did the candidate perform in his/her role?",
                "How would you characterize the candidate's ability to get along with management and peers?",
                "Describe a situation where you and the candidate disagreed? What happened? How was it resolved?",
                "How would you rate the candidate's decision-making skills?",
                "How well did he/she manage crisis, pressure or stress?",
                "What are the candidate's strengths?",
                "What are the candidate weaknesses?",
                "Do you know why the individual left the organization?",
                "Is there anything I haven't asked that you'd like hiring team to be aware of?"
            ]
        };
        return contactTypeQuestions[type];
    }

    
    saveReference() {

        // console.log("this.state", this.state);

        let saveUrl = API_HOST + "/reference_data";
        let method = "post";
        let referenceToSave = {
            reference_is_done : true,
            reference_done_datetime : (new Date()).toLocaleString("US-en"),
            candidate_id: this.state.candidate.id,
            reference_type: $('#relationshipType').find(":selected").val(),
            company_name: $('#companyName').val(),  
            phone: $('#phone').val(),
            email: $('#email').val(),
            details: $('#details').val(),
        }

        // console.log("responseJSONItem", $(".responseJSONItem"));

        let repsonsesJSON = [];
        const userResponses = $(".responseJSONItem");
        for(let i=0;i<userResponses.length;i++){
            // console.log("$(userResponses[i])", $(userResponses[i]));
            const thisResponseObject = $(userResponses[i]);
            const thisResponseText = thisResponseObject.val();
            const responseId = thisResponseObject[0].id;
            const questionNumber = responseId.split("-")[1];
            const thisQuestion = $("#userQuestion-"+questionNumber).text();
            repsonsesJSON.push([
                thisQuestion, thisResponseText
            ])
        }
        // console.log("repsonsesJSON", repsonsesJSON);
        referenceToSave.responses_json = JSON.stringify(repsonsesJSON);

        if(this.state.reference.id){
            referenceToSave["id"] = this.state.reference.id;
            method = "put";
        }
        // console.log("saveUrl", saveUrl);
        // console.log("method", method);
        // console.log("referenceToSave", referenceToSave);

        $.ajax({
            method: method,
            url: saveUrl,
            dataType: "json",
            data: referenceToSave
        }).then(()=>{
            alert("Save complete");

            this.setState({
                referenceMessage : "Your reference has been saved, you may close this window.",
                reference : null
            });

        });

        // alert("SAVE DISABLED, SEE CONSOLE")

        return false;
    }

    renderContactTypeQuestions() {
        
        const referenceType = this.state.reference && this.state.reference.reference_type ? 
            this.state.reference.reference_type : "peer";
        const userSpecificQuestions = this.getContactTypeQuestions(referenceType);

        // console.log("userSpecificQuestions", userSpecificQuestions);

        let questionsJSX = [];
        for(let i=0;i<userSpecificQuestions.length;i++){
            questionsJSX.push(
                <div className="referenceField" key={Utils.guid()}>
                    <div id={"userQuestion-"+i}>{userSpecificQuestions[i]}</div>
                    <div><input type="text" name={"repsonsesJSON-"+i} id={"repsonsesJSON-"+i} className='col-sm-6 responseJSONItem' /></div>
                </div>
            );
        }

        return <div>
            {questionsJSX}
        </div>;

    }

    render() {
        console.log("this.state", this.state)

        return this.state.tokenFound && this.state.reference ? (<div id="referenceInputForm" className="grid-default-dimensions">
            <div>
                Hello <strong>{this.state.reference.first_name} {this.state.reference.last_name}</strong>, Thank you for connecting to the GeoEngineers Job Candidate Reference Tool.  <br /><br />

                <strong>{this.state.candidate.first_name} {this.state.candidate.last_name}</strong> has interviewed with GeoEnigneers, 
                and they have submitted you as a reference in their job application.  
                Please take a moment to review the questions below and enter your responses.  
                Your responses will be confidential and only reviewed by our interviewing team. 
            </div>

            <div className="referenceField">
                <div>Candidate Relationship:</div>
                <div>
         
                    <select value={this.state.reference && this.state.reference.reference_type ? this.state.reference.reference_type : "peer"} name="relationshipType" id="relationshipType" className='col-sm-6' onChange={(event)=>{
                        const selectedValue = event.target.value;
                        const newReferenceState = Utils.copy(this.state.reference);
                        newReferenceState.reference_type = selectedValue;
                        this.setState({
                            reference : newReferenceState
                        });
                    }}>
                        <option value="academic">Academic</option>
                        <option value="peer">Peer</option>
                        <option value="supervisor">Supervisor</option>
                        <option value="client">Client</option>
                    </select>
                
                </div>
            </div>
            
            <div className="referenceField">
                <div>Preferred Phone:</div>
                <div><input type="text" name="phone" id="phone" className='col-sm-6' /></div>
            </div>
            
            <div className="referenceField">
                <div>Preferred Email:</div>
                <div><input type="text" name="email" id="email" className='col-sm-6' /></div>
            </div>
            
            <div className="referenceField">
                <div>Company Name:</div>
                <div><input type="text" name="companyName" id="companyName" className='col-sm-6' /></div>
            </div>

            {this.renderContactTypeQuestions()}
            
            {/* <div className="referenceField">
                <div>Tell us about your experience working with <strong>{this.state.candidate.firstName} {this.state.candidate.lastName}</strong>:</div>
                <div><textarea type="text" name="details" id="details" className='col-sm-6' style={{ minHeight: 250 }} /></div>
            </div> */}

            <div className="referenceField">
                <button name="saveReference" value="saveReference" onClick={()=>{
                    this.saveReference();
                }}>Save and submit</button>
            </div>

        </div>) : <div>{this.state.referenceMessage ? this.state.referenceMessage : "Reference not found."}</div>;
    }
}  

export default ReferenceForm;